<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Informes de auditoria</h4>
            <div class="small text-muted">Administración de todos los informes de auditoria</div>
          </b-col>

          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Informe">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="3">
            <b-row>
              <b-col md="6" class="pr-0">
                <b-form-input type="date" size="md" v-model="filter.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="6" class="pl-0">
                <b-form-input type="date" size="md" v-model="filter.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="6">
            <b-row>
              <b-col sm="4">
                <v-select :options="arr.filter.staff" v-model="filter.staff" placeholder="Staff" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>        
              <b-col sm="4">
                <v-select :options="arr.filter.sellers" v-model="filter.sellers" placeholder="Vendedores" :multiple="false" :select-on-tab="true" @change="filterCustomer(filter.sellers)"></v-select>
              </b-col>       
              <b-col sm="4">
                <v-select :options="arr.filter.customers" v-model="filter.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="3">
            <b-row>
              <b-col sm="6">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-link>
                  <export-excel
                    class = 'pull-right mt-3'
                    :data = "arr.export"
                    worksheet = "Informe de Auditoria"
                    name = "reports-audit.xls">
                    Exportar Datos
                  </export-excel>            
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="formartedItems"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"                          
                    v-if="table.items.length || table.isBusy">

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(date)="data">
                  {{ moment(data.item.date).format('DD MMMM YYYY') }}
                </template>

                <template v-slot:cell(type)="data">
                  <b>{{ data.item.type }}</b>
                </template>

                <template v-slot:cell(responsable)="data">
                  <div v-if="data.item.staff_id">
                    <b-avatar :src="data.item.staff.image"
                              v-if="data.item.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>                         
                    <b-icon icon="blank"></b-icon>
                    <b :title="'STAFF: ' + data.item.staff.name">
                      {{data.item.staff.name}}
                    </b>                                          
                  </div>
                  <div v-else>                    
                    <b-avatar :src="data.item.seller.staff.image"
                              v-if="data.item.seller.staff.image">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark">
                    </b-avatar>     
                    <b-icon icon="blank"></b-icon>
                    <b :title="'VENDEDOR: ' + data.item.seller.staff.name">
                      {{data.item.seller.staff.name}}
                    </b>  
                  </div>                  
                </template>

                <template v-slot:cell(seller)="data">
                  <div v-if="data.item.type == 'AUDITORIA'">
                    <div v-if="data.item.sellers_id > 0">
                      <b-avatar :src="data.item.seller.staff.image"
                                v-if="data.item.seller.staff.image">
                      </b-avatar>
                      <b-avatar v-else                                                                         
                                variant="dark">
                      </b-avatar>                         
                      <b-icon icon="blank"></b-icon>
                      <b :title="'VENDEDOR: ' + data.item.seller.staff.name">
                        {{data.item.seller.staff.name}}
                      </b>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(customers)="data">   
                  <div v-if="data.item.customers_id">
                    <b-avatar :src="data.item.customer.image"
                              v-if="data.item.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>     
                    &nbsp;<b>{{data.item.customer.name}}</b>      
                    <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />
                  </div>
                </template>

                <template v-slot:cell(zone)="data">
                  <div v-if="data.item.customer">
                    <div v-if="data.item.customer.zone">
                      <b>{{ data.item.customer.zone.zone.name }}</b>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations">
                    <div v-if="data.item.observations.length >50" :title="data.item.observations">
                      {{data.item.observations.substr(0, 50)}}
                    </div>
                    <div v-else>
                      {{data.item.observations}}
                    </div>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">  
                    <b-dropdown-header>Acciones</b-dropdown-header>                    
                    
                    <b-dropdown-item @click="irDetail(data.item)" v-if="data.item.type == 'AUDITORIA'">
                      <b-icon icon="card-text"></b-icon> Detalle
                    </b-dropdown-item>
                    
                    <b-dropdown-item @click="sendPrinterOrder(data.item)">
                      <b-icon icon="printer"></b-icon> Imprimir
                    </b-dropdown-item>      

                    <b-dropdown-header v-if="data.item.type == 'AUDITORIA'">Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)" v-if="access.elements.editReport && data.item.type == 'AUDITORIA'">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteReport && data.item.type == 'AUDITORIA'">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterReport()" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              no-close-on-esc
              no-close-on-backdrop>
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>      
          <b-col md="12">
            <b-form-group label="Fecha">
              <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>

          <b-col md="12">
            <b-form-group label="Tipo">                    
              <b-form-select v-model="crud.form.type_report" :options="arr.options.type_report"></b-form-select>                    
            </b-form-group>
          </b-col>

          <b-col md="12" v-show="crud.form.type_report == 'vendedor'">
            <FindObject render="search"
                        type="sellers" 
                        @select-object="loadSellers($event)" 
                        :valueID="crud.form.sellers_id"/>
          </b-col>    

          <b-col md="12" v-show="crud.form.type_report == 'cliente'">
            <FindObject render="search"
                        type="customers" 
                        @select-object="loadCustomers($event)" 
                        :valueID="crud.form.customers_id"/>           
          </b-col>    

          <b-col md="12">
            <b-form-group label="Observaciones">
              <b-form-textarea                
                v-model="crud.form.observations"
                placeholder="Ingresar las observaciones..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>         
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject' 
  import Printer from '@/components/inc/printer/printer'
  import Vue from 'vue'
  import excel from 'vue-excel-export'
  import Storage from '@/handler/storageSession'

  Vue.use(excel)

  export default {
    components: {
      FindObject,
      Printer,       
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.INFORME_AUDITORIA,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudReport',
          elements: {
            editReport: true,
            deleteReport: true             
          }
        }, 
        table : {
          items: [],
          fields: [       
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'type', label: 'Tipo', class: "align-middle"},
            {key: 'responsable', label: 'Responsable', class: "align-middle"},
            {key: 'seller', label: 'Vendedor', class: "align-middle"},
            {key: 'customers', label: 'Cliente', class: "align-middle"},
            {key: 'zone', label: 'Zona', class: "align-middle"},
            {key: 'observations', label: 'Observaciones', class: "align-middle"},            
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                 
        },
        crud: {
          form: {
            id: 0,
            date: '',
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,
            read: false,
            type_report: ''
          },     
          print: {
            reference: '',
            id: 0,
            key: 0,
          }     
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        arr: {
          export: [],
          filter : {
            customers: [],
            staff: [],
            sellers: [],
          },
          options: {
            type_report: [
              { text: 'Vendedor', value: 'vendedor' },
              { text: 'Cliente', value: 'cliente' },
            ],
          }
        },
        filter: {
          date_start: '',
          date_end: '',
          customers: [],
          staff: [],
          sellers: [],
        },   
        customersID: 0   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.editReport = Helper.hasAccessElement(this.access, 'edit_report')
      this.access.elements.deleteReport = Helper.hasAccessElement(this.access, 'delete_report')       
      /* Fin configuracion */
    },  
    mounted() {      
      this.customersID = this.$route.params.customersID
      if(this.customersID) {
        this.validCustomers()
      }

      this.filterLoad()
      this.filterReport()
    },
    computed: {
      formartedItems () {
        if (!this.table.items) return []
        return this.table.items.map(item => {
          if(item.type == 'AUDITORIA'){
            item._rowVariant  = 'danger'
          }
          
          return item
        })
      }
    },    
    methods: {
      validCustomers() {        
        this.add()
        this.crud.form.customers_id = this.customersID
      },

      add() {
        this.crud.form.id = 0
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.customers_id = 0
        this.crud.form.customers = null
        this.crud.form.read = false
        this.crud.form.type_report = ''

        this.modal.form.title = "Nuevo Reporte"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.date = item.date 
        this.crud.form.observations = item.observations
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id
        this.crud.form.customers_id = item.customers_id
        this.crud.form.customers = item.customer
        this.crud.form.read = item.read

        if(item.sellers_id){
          this.crud.form.type_report = 'vendedor'
        }

        if(item.customers_id){
          this.crud.form.type_report = 'cliente'
        }
                
        this.modal.form.title = "Editar Reporte"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.customers = item.customer

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') realizado al cliente ('+ this.crud.form.customers.name + ')?', {
          title: 'Borrar Reporte',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminar(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterReport()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la operación?', {
          title: 'Guardar Reporte',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            if (this.crud.form.id) {
              var result = serviceAPI.editar(this.crud.form);
            } else {
              var result = serviceAPI.agregar(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterReport()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      },
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id        
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0
        }
      }, 
      loadSellers (object) {
        if(object){
          this.crud.form.sellers = object
          this.crud.form.sellers_id = object.id
        } else {
          this.crud.form.sellers = null
          this.crud.form.sellers_id = 0
        }
      },     
      filterLoad() {
        this.filterLoadCustomers()
        this.filterLoadStaff()     
        this.filterLoadSellers()   
        this.filter.date_start = moment().subtract(7, 'days').format('YYYY-MM-DD') 
        this.filter.date_end = moment().format('YYYY-MM-DD') 

        this.restoreFilterStorage() 
      },
      filterCustomer(seller){        
        if(seller){
          if(!seller["code"]){
            this.filterLoadCustomers()
          } else {
            this.filterLoadCustomersSeller(seller["code"])
          }
        } else {
          this.filterLoadCustomers()
        }
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.customers = []
          data.forEach(element => {            
            this.arr.filter.customers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadCustomersSeller(id) {
        var result = serviceAPI.obtenerClientesByZonas({ sellers_id: id })
        result.then((response) => {
          var data = response.data    

          this.arr.filter.customers = []
          data.forEach(element => {            
            this.arr.filter.customers.push({ code: element.id, label: element.code + ' | ' + element.name })
          });    

          if(this.customersID) {
            this.validCustomers()
          }
        })   
      },
      
      filterLoadStaff() {
        var result = serviceAPI.obtenerStaff()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.staff = []
          data.forEach(element => {                            
            if(!element.seller) {             
              this.arr.filter.staff.push({ code: element.id, label: element.name })    
            }                    
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filter.sellers = []
          data.forEach(element => {                            
            this.arr.filter.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },      
      filterReport(){
        this.table.isBusy = true
           
        var result = serviceAPI.filtrarReport(this.filter, this.table.currentPage)        
        result.then((response) => {        
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data          
          this.prepareExport(data.data)

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }               

          this.table.isBusy = false           
        })
        .catch(error => {            
          this.table.isBusy = false                    
          this.$awn.alert(Error.showError(error))
        });          
      }, 
      prepareExport(data) {
        data.forEach(element => {
          var staffID = 0            
          var staffName = ''
          var sellersID = 0
          var sellersName = ''
          var customersID = 0
          var customersCode = ''
          var customersName = ''

          if(element.staff_id) {
            staffID = element.staff.id
            staffName = element.staff.name
          } else {
            sellersID = element.seller.id
            sellersName = element.seller.name
          }

          if(element.customers_id) {
            customersID = element.customer.id
            customersCode = element.customer.code
            customersName = element.customer.name            
          }

          this.arr.export.push({
            id: element.id,
            date: element.date,
            sellersID: sellersID,
            sellersName: sellersName,
            staffID: staffID,
            staffName: staffName,
            customersID: customersID,
            customersCode: customersCode,
            customersName: customersName,
            observations: element.observations
          })
        });
      },
      
      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_report_audit', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_report_audit')) {
          this.filter = JSON.parse(Storage.getValue('filter_report_audit'))
        }         
      },

      irDetail(item){
        this.saveFilterStorage()        
        this.$router.push({ name: 'ReportsAuditStaffDetailReport', params: {reportID: item.id} })
      },
      sendPrinterOrder(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id

        if(item.type == 'NOVEDADES'){
          this.crud.print.reference='reports-news'
        } else if(item.type == 'COBRANZAS'){
          this.crud.print.reference='reports-payment'
        } else if(item.type == 'VISITAS'){
          this.crud.print.reference='reports-visit'
        } else if(item.type == 'SERVICIOS'){
          this.crud.print.reference='reports-service'
        } else if(item.type == 'AUDITORIA'){
          this.crud.print.reference='reports-audit'
        } else if(item.type == 'MARKETING'){
          this.crud.print.reference='reports-marketing'
        }
      }
    }    
  }
</script>
<style>
  .report-news-crud-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>